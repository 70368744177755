import React from 'react';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import {PRODUCTION} from '@constants/base';

function ClientBugsnag() {
  const key = process?.env?.NEXT_PUBLIC_BUGSNAG_KEY ?? 'c68bdfc62f0d779b993b7ad5a0017570';

  Bugsnag.start({
    apiKey: key,
    releaseStage: process.env.NODE_ENV,
    enabledReleaseStages: [PRODUCTION],
    maxBreadcrumbs: 10,
    plugins: [new BugsnagPluginReact()],
    onError: event => {
      /* Omissions: we don't control these, third party */
      const omitErrorStrings = [
        'ResizeObserver',
        'unhandledrejection handler received a non-error',
        "Cannot read properties of null (reading 'removeChild')",
        'Illegal invocation',
        'cloudflare',
        /* acbe specific */
        'acsbJS is not defined',
        "properties of null (reading 'prepend')",
      ];
      /* Check if any omission is included in the error message */
      const checkIncludes = (text?: string, search?: string): boolean => {
        if (!text || !search) return false;
        return Boolean(text?.includes(search));
      }

      const shouldOmit = event.errors?.some(error =>
        omitErrorStrings.some(omission =>
          checkIncludes(error?.errorMessage, omission) ||
          checkIncludes(event?.originalError?.message, omission) ||
          checkIncludes(event?.originalError?.stack, omission)
        )
      );

      /* Return false to omit the error from being reported, true otherwise */
      return !shouldOmit;
    },
  });

  return Bugsnag.getPlugin('react')!.createErrorBoundary(React);
}

export default ClientBugsnag;

export const logWarning = (message: any) => {
  Bugsnag.notify(message, event => {
    event.severity = 'warning';
  });
};
